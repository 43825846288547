import React, { Component } from "react";

import "react-multi-carousel/lib/styles.css";

import CardProdotto from "./CardProdotto";

//import PropTypes from 'prop-types';

const styles = {
  root: {
    width: "100%",
    textAlign: "center",
    position: "relative",
  },
  div_paper: {
    width: "100%",
    position: "relative",
    padding: "4px",
  },
};

export default class ProdottoCard extends Component {
  render() {
    return (
      <div style={styles.root}>
        <div key={this.props.id} style={styles.div_paper}>
          <CardProdotto prodottoId={this.props.id} />
        </div>
      </div>
    );
  }
}
