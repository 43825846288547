import React, { Component } from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Link from '@material-ui/core/Link';

import logo from '../logo.png';

const styles = {
    appbar: {
        backgroundColor: "white"
    },
    logo: {
        height: '70px'
    }
};

export default class CustomHeader extends Component {

  render() {

    return (
            <AppBar style={styles.appbar} position="static" >
                <Toolbar>
                    <Link 
                    href="https://mercatinrete.it" 
                    underline='none'
                    target="_blank"
                    >
                        <img src={logo} style={styles.logo} alt="mercatinrete" />
                    </Link>
                </Toolbar>
            </AppBar>

    );
  }

}