import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import React, { Component, Fragment } from "react";


import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ErrorDialog from "../../components/ErrorDialog";
import ProdottoCard from "./ProdottoCard";
const styles = {
  container: {
    height: "100vh",
    padding:"30px",
  },
  
  title: {
    fontFamily: "Raleway",
    fontWeight: "bold",
    padding: "20px",
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  gridList: {
    width: 300,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
};

export default class ListaProdottiPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prodotti: [],
      prodottiOptions: [],
      selectedProdottoNome: null,
      selectedProdottoId: null,
      errorDialogVisible: false,
      errorDialogMessage: "",
    };
  }

  getProdotti = (query) => {
    const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
    fetch(
      ENDPOINT +
        "/api/acquisti/prodotti?consumatoreview.specified=true&size=1000&cestinato.equals=false",
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        let options = [];
        let prodottiArray = [];
        let opt = {};
        for (let i = 0; i < result.length; i++) {
          opt = {
            value: result[i].prodottoId,
            name: result[i].nome,
          };
          options.push(opt);
          prodottiArray.push(result[i]);
        }
        this.setState({
          prodotti: prodottiArray,
          prodottiOptions: options,
        });
      })
      .catch((error) => {
        let msg = error.message;
        if (msg === "Failed to fetch") {
          msg =
            "Servizio temporaneamente non disponibile. Riprovare più tardi.";
        } else {
          msg =
            "Si è verificato un errore. Caricamento dell'elenco dei prodotti fallito.";
        }
        this.setState({
          errorDialogVisible: true,
          errorDialogMessage: msg,
        });
      });
  };

  componentDidMount() {
    this.getProdotti();
    const script = document.createElement("script");

    script.src = "//js.hs-scripts.com/139617068.js";
    script.async = true;

    document.body.appendChild(script);
  }
  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  render() {
    return (
      <Fragment>
        <CssBaseline />
        <Container fixed style={styles.container}>
          <div style={styles.root}>
        
          <GridList cols={3} cellHeight={400} className={styles.gridList}>
        
        {this.state.prodotti.map((item, index) => (
          <GridListTile  key={item.nome}>
             <ProdottoCard id={item.prodottoId}/>
          
          </GridListTile>
        ))}
      </GridList>
           
            {/*
                             <Grid container spacing={1} style={{marginTop: '40px'}} >
                            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                                <ProdottoButton prodottoId={97} />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                                <ProdottoButton prodottoId={98} />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                                <ProdottoButton prodottoId={99} />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                                <ProdottoButton prodottoId={100} />
                            </Grid>
                            </Grid> */}
          </div>
        </Container>
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseBtnClicked={this.closeErrorDialog}
        />
      </Fragment>
    );
  }
}
