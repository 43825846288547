import React from 'react';
import { Route, BrowserRouter, Switch, useParams } from "react-router-dom";
import HomePage from './scenes/home/HomePage';
import ProdottoPage from './scenes/prodotto/ProdottoPage';
import CustomHeader from './components/CustomHeader';
import './App.css';
import ListaProdottiPage from './scenes/ListaProdotti/ListaProdottiPage';

export default function App() {

  return (
    <BrowserRouter>
      <div className="App">
        <CustomHeader />
        <Switch>
          <Route path="/prodotto/:prodottoId" component={WrapperProdottoPage} />
          <Route path="/lista" render={() => <ListaProdottiPage />} />

          {/* If none of the previous routes render anything,
            this route acts as a fallback.

            Important: A route with path="/" will *always* match
            the URL because all URLs begin with a /. So that's
            why we put this one last of all */}
          <Route path="/" render={() => <HomePage />} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

function WrapperProdottoPage() {
  const { prodottoId } = useParams();
  return (
    <ProdottoPage prodottoId={prodottoId} />
  );
}
