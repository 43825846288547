import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React, { Component, Fragment } from "react";

import SelectSearch from "react-select-search";

import ErrorDialog from "../../components/ErrorDialog";
import GoToListaButton from "../../components/GoToListaButton";
import ProdottoButtonHome from "../../components/ProdottoButtonHome";

const styles = {
  container: {
    height: "100vh",
  },
  root: {
    textAlign: "center",
    color: "black",
    paddingTop: "20px",
  },
  title: {
    fontFamily: "Raleway",
    fontWeight: "bold",
    padding: "20px",
  },
};

export default class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prodotti: [],
      prodottiOptions: [],
      selectedProdottoNome: null,
      selectedProdottoId: null,
      errorDialogVisible: false,
      errorDialogMessage: "",
    };
  }

  handleSelectedProdotto = (value, item) => {
    let selectedNome = null;
    for (let i = 0; i < this.state.prodotti.length; i++) {
      if (this.state.prodotti[i].id === value) {
        selectedNome = this.state.prodotti[i].nome;
        break;
      }
    }

    this.setState({
      selectedProdottoId: value,
      selectedProdottoNome: selectedNome,
    });
  };

  getProdotti = (query) => {
    const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
    fetch(
      ENDPOINT +
        "/api/acquisti/prodotti?consumatoreview.specified=true&cestinato.equals=false&size=6&nome.contains=" +
        query,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        let options = [];
        let prodottiArray = [];
        let opt = {};
        for (let i = 0; i < result.length; i++) {
          opt = {
            value: result[i].prodottoId,
            name: result[i].nome,
          };
          options.push(opt);
          prodottiArray.push(result[i]);
        }
        this.setState({
          prodotti: prodottiArray,
          prodottiOptions: options,
        });
      })
      .catch((error) => {
        let msg = error.message;
        if (msg === "Failed to fetch") {
          msg =
            "Servizio temporaneamente non disponibile. Riprovare più tardi.";
        } else {
          msg =
            "Si è verificato un errore. Caricamento dell'elenco dei prodotti fallito.";
        }
        this.setState({
          errorDialogVisible: true,
          errorDialogMessage: msg,
        });
      });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  render() {
    return (
      <Fragment>
        <CssBaseline />
        <Container fixed style={styles.container}>
          <div style={styles.root}>
            <Typography variant="h4" style={styles.title}>
              Benvenuto su MercatInRete
            </Typography>
            <Grid container spacing={1} style={{ marginTop: "40px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectSearch
                  options={this.state.prodottiOptions}
                  placeholder={
                    this.state.selectedProdottoNome === null
                      ? "Inserisci il nome di un prodotto"
                      : this.state.selectedProdottoNome
                  }
                  onChange={this.handleSelectedProdotto}
                  getOptions={this.getProdotti}
                  value={this.state.selectedProdottoId}
                  search
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ marginTop: "10px" }}
              >
                <ProdottoButtonHome
                  disabled={
                    this.state.selectedProdottoId === null ? true : false
                  }
                  prodottoId={this.state.selectedProdottoId}
                />
                <GoToListaButton />
              </Grid>
            </Grid>
            {/*
                             <Grid container spacing={1} style={{marginTop: '40px'}} >
                            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                                <ProdottoButton prodottoId={97} />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                                <ProdottoButton prodottoId={98} />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                                <ProdottoButton prodottoId={99} />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                                <ProdottoButton prodottoId={100} />
                            </Grid>
                            </Grid> */}
          </div>
        </Container>
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseBtnClicked={this.closeErrorDialog}
        />
      </Fragment>
    );
  }
}
