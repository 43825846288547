import React from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

export default function ProdottoButton(props) {
  let history = useHistory();
  return (
    <div
      style={{ width: "210px", height: "210px", margin: "auto" }}
      onClick={() => {
        history.push(`/prodotto/${props.prodottoId}`);
      }}
    >
      <img
        style={{ width: "100%", height: "100%", margin: "auto" }}
        src={props.url}
        alt={"foto_prodotto_" + props.prodottoId}
      />

      <Typography
        variant="h6"
        style={{ fontFamily: "Raleway", fontWeight: "300" }}
        gutterBottom
      >
        {props.prodotto.marca === null ? "" : props.prodotto.marca}
      </Typography>
      <Typography variant="h5" gutterBottom>
        {props.prodotto.nome === null ? "" : props.prodotto.nome}
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom
        style={{ fontFamily: "Raleway" }}
      >
        {props.prodotto.prezzoListino + " €  (iva inclusa)"}
      </Typography>
    
    </div>
  );
}

ProdottoButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  prodottoId: PropTypes.number,
};
