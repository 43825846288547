import React from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import PropTypes from 'prop-types';

export default function ProdottoButtonHome(props) {

  let history = useHistory();
  return (
    <Button
      disabled={props.disabled}
      onClick={() => { history.push(`/prodotto/${props.prodottoId}`) }}
      variant="contained"
      //color="default"
      //size="medium"
      style={{ 
          fontFamily:'Raleway',
          fontSize: '12px',
          margin:'10px',
          backgroundColor: props.disabled ? '#e0e0e0' : '#49b6af'
      }}
    >
      {"Vai alla pagina del prodotto"}
    </Button>
  );

}

ProdottoButtonHome.propTypes = {
  disabled: PropTypes.bool.isRequired,
  prodottoId: PropTypes.number
}