import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Map, GoogleApiWrapper, Marker, Circle} from 'google-maps-react';

import PropTypes from 'prop-types';

const styles = {
    root: {
        textAlign: 'center',
        position: 'relative'
    },
    mapcontainer: {
        height: '320px',
        position: 'relative'
    },
    div_spinner: {
        height: '320px',
        paddingTop: '40px'
    },
    spinner: {
        color: "#FF6D79"
    },
}

export class MappaProdotto extends Component {

    // onMapClicked = () => {
    //     console.log("Clicked map");
    // }

    render() {
        let defaultPoint = {
            lat: 43.7698746,
            lng: 11.2520189
        }; 
        if (this.props.mercati !== null && this.props.mercati.length>0) {
            defaultPoint = {
                lat: this.props.mercati[0].latitudine,
                lng: this.props.mercati[0].longitudine,
            };
        }
        return (
            <div style={styles.root} >
                <Typography variant="h6" style={{fontFamily:'Raleway', fontWeight:'300'}} gutterBottom>
                    Con l’App MIR puoi provare gratis e senza alcun impegno il prodotto ritirandolo o ricevendolo direttamente a casa tua nelle aree indicate nella mappa.
                </Typography>
                { this.props.loading? 
                    <div style={styles.div_spinner}>
                        <CircularProgress
                            style={styles.spinner}
                            size={42}
                        />
                    </div>
                    :
                    <Map 
                    google={this.props.google} 
                    initialCenter={defaultPoint}
                    zoom={12}
                    containerStyle={styles.mapcontainer}
                    //onClick={this.onMapClicked}
                    >
                        { this.props.mercati.map((value,index) => (
                                <Marker
                                    key={index}
                                    name={value.nome}
                                    position={{
                                        lat: value.latitudine,
                                        lng: value.longitudine
                                    }}
                                    title={value.nome}
                                />
                            ))
                        }
                        {  this.props.mercati.map((value,index) => (
                                <Circle
                                    key={index}
                                    radius={4000}
                                    center={{
                                        lat: value.latitudine,
                                        lng: value.longitudine
                                    }}
                                    //onMouseover={() => console.log('mouseover')}
                                    //onClick={() => console.log('click')}
                                    //onMouseout={() => console.log('mouseout')}
                                    strokeColor='transparent'
                                    strokeOpacity={0}
                                    strokeWeight={5}
                                    fillColor='#FF0000'
                                    fillOpacity={0.1}
                                />
                            ))
                        }
                    </Map>

                }
            </div>
        );
    }

}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyCtcQXrgRsaaereB-Blf95C0fKLHlQ31IA")
})(MappaProdotto)

MappaProdotto.propTypes = {
    mercati: PropTypes.array,
    loading: PropTypes.bool.isRequired
}