import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import CardProdottoSimile from './CardProdottoSimile';

//import PropTypes from 'prop-types';

const styles = {
    root: {
        width: '100%',
        textAlign: 'center',
        position: 'relative',
    },
    div_paper: {
        width: '100%',
        position: 'relative',
        padding: '4px'
    }
}

export default class ProdottiSimili extends Component {

    render() {
        const responsive = {
            superLargeDesktop: {
              // the naming can be any, depends on you.
              breakpoint: { max: 4000, min: 3000 },
              items: 4
            },
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 3
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1
            }
        };
        //const ids = [140, 141, 167, 187];
        const ids = [97, 99, 98, 172];
        return (
            <div style={styles.root} >
                <Typography variant="h6" gutterBottom style={{ textAlign: 'left', fontFamily: 'Raleway', fontWeight: '300' }} >
                    Prodotti simili
                </Typography>
                <Typography variant="subtitle1" style={{ textAlign: 'left', fontFamily: 'Raleway' }} gutterBottom>
                    Altri prodotti che potrebbero interessarti:
                </Typography>
                <Carousel
                    centerMode={false}
                    responsive={responsive}
                    itemClass="carousel-item-padding-10-px"
                >
                    { ids.map((value,index) => (
                        <div key={index} style={styles.div_paper}>
                            <CardProdottoSimile prodottoId={value} />
                        </div>
                        ))
                    }
                </Carousel>
            </div>
        );
    }

}
