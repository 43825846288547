import React, { Component, Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import PropTypes from 'prop-types';

const styles = {
    paper: {
        maxWidth: '220px',
        minHeight: '380px',
        margin: 'auto',
    },
    img: {
        width: '100%',
        height: '100%',
        margin: 'auto',
    },
    div_spinner: {
        minHeight: '400px',
        paddingTop: '20px',
        paddingBottom: '20px'
    },
    spinner: {
        color: "#FF6D79",
        top: '50%',
        left: '50%'
    },
}

export default class CardProdottoSimile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            prodotto: null
        }
    }

    componentDidMount() {
        this.getProdotto(this.props.prodottoId);
    }

    getProdotto = (prodottoId) => {
        const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
        const URL = ENDPOINT + '/api/acquisti/prodotti?consumatoreview.specified=true&id.equals=' + prodottoId;
        fetch(URL, {
            method: 'GET',
            headers: {
            },
            withCredentials: false
        })
        .then(response => {
            const status = response.status;
            if (status === 200) {
                return response.json();
            } else {
                throw new Error(status);
            }
        })
        .then(result => {
            if (result.length === 0) {
                this.setState({
                    loading: false
                });
            } else {
                this.setState({
                    loading: false,
                    prodotto: result[0],
                });
            }
        })
        .catch(error => {
            this.setState({
                loading: false
            });
        });
    }

    render () {
        const URL = process.env.REACT_APP_BACKEND_ENDPOINT;
        const URL_FOTO = URL + "/api/prodotti/foto/";
        return (
            <Paper elevation={3} style={styles.paper} >
                { this.state.loading ?
                    <div style={styles.div_spinner}>
                        <CircularProgress
                            style={styles.spinner}
                            size={42}
                        />
                    </div>
                    :
                    <Fragment>
                        {
                            this.state.prodotto === null ? 
                            <Typography variant="h6" style={{ fontFamily: 'Raleway', fontWeight: '300', paddingTop:'20px' }} gutterBottom>
                                Nessun prodotto trovato
                            </Typography>
                            :
                            <Fragment>
                                <div style={{ width:'210px', height:'210px', margin:'auto'}}>
                                    <img style={styles.img} src={URL_FOTO + this.state.prodotto.fotoPrincipale} alt={"foto_prodotto_" + this.state.prodotto.prodottoId} />
                                </div>
                                <Typography variant="h6" style={{ fontFamily: 'Raleway', fontWeight: '300' }} gutterBottom>
                                    {this.state.prodotto.marca === null ? "" : this.state.prodotto.marca}
                                </Typography>
                                <Typography variant="h5" gutterBottom>
                                    {this.state.prodotto.nome === null ? "" : this.state.prodotto.nome}
                                    </Typography>
                                <Typography variant="subtitle1" gutterBottom style={{ fontFamily: 'Raleway' }} >
                                    {this.state.prodotto.prezzoListino + " €  (iva inclusa)"}
                                </Typography>
                            </Fragment>
                        }
                    </Fragment>
                }
            </Paper>
        );
    }

}

CardProdottoSimile.propTypes = {
    prodottoId: PropTypes.number.isRequired
}