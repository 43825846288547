import React from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import PropTypes from 'prop-types';

export default function GoToListaButton(props) {

  let history = useHistory();
  return (
    <Button
      disabled={props.disabled}
      onClick={() => { history.push(`/lista`) }}
      variant="contained"
      //color="default"
      //size="medium"
      style={{ 
          fontFamily:'Raleway',
          fontSize: '12px',
          margin:'10px',
          backgroundColor: '#49b6af'
      }}
    >
      {"Vai alla lista dei prodotti"}
    </Button>
  );

}

GoToListaButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  prodottoId: PropTypes.number
}