import React, { Component, Fragment } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import ImmaginiProdotto from "./ImmaginiProdotto";
import DatiProdotto from "./DatiProdotto";
import MappaProdotto from "./MappaProdotto";
import ProdottiSimili from "./ProdottiSimili";
import ErrorDialog from "../../components/ErrorDialog";
import Cookies from 'universal-cookie';
import PropTypes from "prop-types";
import { HeadTags } from "../../components/HeadTags";






const styles = {
  root: {
    height: "100%",
  },
  main_div: {
    flexGrow: 1,
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  griditem: {
    paddingBottom: "20px",
    //minHeight: '400px'
  },
  div_spinner: {
    minHeight: "400px",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  spinner: {
    color: "#FF6D79",
    top: "50%",
    left: "50%",
    //marginTop: -12,
    //padding: '10px'
    //marginLeft: -12,
  },
  text: {
    fontFamily: "Raleway",
    padding: "10px 0px 10px 0px",
  },
};

export default class ProdottoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingMercato: true,
      prodotto: null,
      varianti: null,
      tempMercati: [],
      mercati: [],
      notFound: true,
      totMercatiToBeFetched: 0,
      totFetchedMercati: 0,
      errorDialogVisible: false,
      errorDialogMessage: "",
    };
  }
 
  
  componentDidMount() {
    this.getProdotto(this.props.prodottoId);
    this.incrementView(this.props.prodottoId);
    const script = document.createElement("script");

    script.src = "//js.hs-scripts.com/139617068.js";
    script.async = true;

    document.body.appendChild(script);
  }
  incrementView = (prodottoId) => {
    const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
    const URL = ENDPOINT + "/api/analytics/view/" + prodottoId;
    fetch(URL, {
      method: "PUT",
      headers: {},
      withCredentials: false,
    });
  };

  getProdotto = (prodottoId) => {
    const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
    const URL =
      ENDPOINT +
      "/api/acquisti/prodotti?consumatoreview.specified=true&id.equals=" +
      prodottoId;
    fetch(URL, {
      method: "GET",
      headers: {},
      withCredentials: false,
    })
      .then((response) => {
        const status = response.status;
        if (status === 200) {
          return response.json();
        } else {
          throw new Error(status);
        }
      })
      .then((result) => {
        if (result.length === 0) {
          this.setState({
            loading: false,
            notFound: true,
          });
        } else {
          this.setState({
            prodotto: result[0],
          });
          this.getVarianti(result[0].ambulanteId, result[0].prodottoId);
        }
      })
      .catch((error) => {
        let errorMsg = "Si è verificato un errore. Caricamento fallito.";
        const msg = error.message;
        if (msg === "Failed to fetch") {
          errorMsg =
            "Servizio temporaneamente non disponibile. Riprovare più tardi.";
        }
        this.setState({
          loading: false,
          errorDialogVisible: true,
          errorDialogMessage: errorMsg,
        });
      });
  };

  getVarianti = (ambulanteId, prodottoId) => {
    const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
    const URL =
      ENDPOINT +
      "/api/acquisti/varianti?ambulanteId.equals=" +
      ambulanteId +
      "&prodottoId.equals=" +
      prodottoId;
    fetch(URL, {
      method: "GET",
      headers: {},
      withCredentials: false,
    })
      .then((response) => {
        const status = response.status;
        if (status === 200) {
          return response.json();
        } else {
          throw new Error(status);
        }
      })
      .then((result) => {
        // NB: se la variante non esiste per quel determinato ambulante, allora il prodotto non dovrebbe
        //essere mostrato al cliente.
       
          this.setState({
            loading: false,
            varianti: result,
            notFound: false,
            totMercatiToBeFetched: result.length,
          });
          // For each variante get associated mercato
          for (let i = 0; i < result.length; i++) {
            this.getMercato(result[i].mercatoId);
          }
        
      })
      .catch((error) => {
        let errorMsg = "Si è verificato un errore. Caricamento fallito.";
        const msg = error.message;
        if (msg === "Failed to fetch") {
          errorMsg =
            "Servizio temporaneamente non disponibile. Riprovare più tardi.";
        }
        this.setState({
          loading: false,
          errorDialogVisible: true,
          errorDialogMessage: errorMsg,
        });
      });
  };

  getMercato = (mercatoId) => {
    const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
    const URL = ENDPOINT + "/api/mercati?id.equals=" + mercatoId;
    fetch(URL, {
      method: "GET",
      headers: {},
      withCredentials: false,
    })
      .then((response) => {
        const status = response.status;
        if (status === 200) {
          return response.json();
        } else {
          throw new Error(status);
        }
      })
      .then((result) => {
        if (result.length === 0) {
          this.setState({
            errorDialogVisible: true,
            errorDialogMessage: "Mercato non trovato",
          });
        } else {
          let totmercati = this.state.totFetchedMercati + 1;
          let mercatiList = this.state.tempMercati;
          let foundMercato = false;
          for (let i = 0; i < mercatiList.length; i++) {
            if (mercatiList[i].id === result[0].id) {
              foundMercato = true;
              break;
            }
          }
          if (!foundMercato) {
            mercatiList.push(result[0]);
          }
          this.setState({
            totFetchedMercati: totmercati,
            tempMercati: mercatiList,
          });
          if (totmercati === this.state.totMercatiToBeFetched) {
            this.setState({
              loadingMercato: false,
              mercati: mercatiList,
            });
          }
        }
      })
      .catch((error) => {
        let errorMsg =
          "Si è verificato un errore. Non è stato possibile recuperare la posizione su mappa.";
        const msg = error.message;
        if (msg === "Failed to fetch") {
          errorMsg =
            "Servizio temporaneamente non disponibile. Riprovare più tardi.";
        }
        this.setState({
          loading: false,
          errorDialogVisible: true,
          errorDialogMessage: errorMsg,
        });
      });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  render() {
    return (
      
      <Fragment>
        
        {this.state.loading ? (
          <div style={styles.div_spinner}>
            <CircularProgress style={styles.spinner} size={42} />
          </div>
        ) : (
          <>
          
            <HeadTags
              title={this.state.prodotto.nome}
              metaDescription={this.state.prodotto.descrizione}
              imageId={this.state.prodotto.prodottoId}
            ></HeadTags>
            <CssBaseline />
            <Container id="page_container" style={styles.root}>
              <div style={styles.main_div}>
                {this.state.loading ? (
                  <div style={styles.div_spinner}>
                    <CircularProgress style={styles.spinner} size={42} />
                  </div>
                ) : this.state.notFound ? (
                  <div>
                    <Typography variant="h5" style={styles.text}>
                      Nessun prodotto trovato.
                    </Typography>
                  </div>
                ) : (
                  <Grid container spacing={2}>
                    <Grid
                      item
                      id="foto_grid_item"
                      style={styles.griditem}
                      xs={12}
                      sm={7}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <ImmaginiProdotto prodotto={this.state.prodotto} />
                    </Grid>
                    <Grid
                      item
                      style={styles.griditem}
                      xs={12}
                      sm={5}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <DatiProdotto
                        prodotto={this.state.prodotto}
                        varianti={this.state.varianti}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                     <MappaProdotto
                        mercati={this.state.mercati}
                        loading={this.state.loadingMercato}
                      />
                    </Grid>
                
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <ProdottiSimili />
                    </Grid>
                    */}
                  </Grid>
                )}
              </div>
            </Container>
            <ErrorDialog
              open={this.state.errorDialogVisible}
              message={this.state.errorDialogMessage}
              onCloseBtnClicked={this.closeErrorDialog}
            />
          </>
        )}
      </Fragment>
    );
  }
}

ProdottoPage.propTypes = {
  prodottoId: PropTypes.string.isRequired,
};
