import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import {
  CarouselProvider,
  Slider,
  Slide,
  Image,
  Dot,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { IconContext } from "react-icons";
import Cookies from 'universal-cookie';

import PropTypes from "prop-types";

import logo from "../../logo.png";
import { IconButton } from "@material-ui/core";
const cookies = new Cookies();
const styles = {
  /* img_button: {
        width: '76px',
        height: '76px',
        backgroundColor: 'white',
        border: '1px solid lightgray',
        margin: '2px',
        outlineColor: '#49b6af'
    }, */
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
};

export default class ImmaginiProdotto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      like: cookies.get(this.props.prodotto.prodottoId),
      imgsSizes: [
        {
          width: "100%",
          height: "100%",
        },
        {
          width: "100%",
          height: "100%",
        },
        {
          width: "100%",
          height: "100%",
        },
        {
          width: "100%",
          height: "100%",
        },
        {
          width: "100%",
          height: "100%",
        },
      ],
    };
  }

  componentDidMount() {
    this.calculatePhotosAspectRatio();
  }

  calculatePhotosAspectRatio() {
    const imgIds = this.props.prodotto.fotoAggiuntive;
    if (imgIds !== null && imgIds.length > 0) {
      for (let i = 0; i < imgIds.length; i++) {
        this.loadImageAndSetAspectRatio(imgIds[i], i);
      }
    }
  }
  likeApi = () => {
    const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
    const URL = ENDPOINT + "/api/analytics/like";
    let like = this.state.like;
    let body = {
      prodottoId: this.props.prodotto.prodottoId,
      like: like,
    };
    console.log(body);

    if (this.state.like === true) {
   
      cookies.set(this.props.prodotto.prodottoId, true, { path: "/" });
    } else {
      cookies.remove(this.props.prodotto.prodottoId);
    }

    fetch(URL, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      credentials: "include",
    });
  };
  AddLinkToPhoto = (id) => {
    const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
    const URL = ENDPOINT + "/api/prodotti/foto/like/" + id;
    fetch(URL, {
      method: "PUT",
      headers: {},
      withCredentials: false,
    })
      .then((response) => {
        const status = response.status;
        if (status === 200) {
          return response.json();
        } else {
          throw new Error(status);
        }
      })
      .then((result) => {
        // NB: se la variante non esiste per quel determinato ambulante, allora il prodotto non dovrebbe
        //essere mostrato al cliente.
        if (result.length === 0) {
          this.setState({
            loading: false,
            notFound: true,
          });
        } else {
          this.setState({
            loading: false,
            varianti: result,
            notFound: false,
            totMercatiToBeFetched: result.length,
          });
          // For each variante get associated mercato
          for (let i = 0; i < result.length; i++) {
            this.getMercato(result[i].mercatoId);
          }
        }
      })
      .catch((error) => {
        let errorMsg = "Si è verificato un errore. Caricamento fallito.";
        const msg = error.message;
        if (msg === "Failed to fetch") {
          errorMsg =
            "Servizio temporaneamente non disponibile. Riprovare più tardi.";
        }
        this.setState({
          loading: false,
          errorDialogVisible: true,
          errorDialogMessage: errorMsg,
        });
      });
  };

  loadImageAndSetAspectRatio = (id, index) => {
    const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;
    const URL = BASE_URL + "/api/prodotti/foto/";
    let that = this;
    let image = document.createElement("img");
    image.src = URL + id;
    image.onload = function () {
      let innerwidth = window.innerWidth;
      let innerheight = window.innerHeight;
      let imageMaxWidth = 498;
      let imageMaxHeight = 373;
      if (innerwidth <= 1280 && innerwidth >= 1242) {
        imageMaxWidth = 434;
        imageMaxHeight = 325;
      } else if (innerwidth <= 1241 && innerwidth >= 1024) {
        imageMaxWidth = 352;
        imageMaxHeight = 264;
      } else if (innerwidth <= 1023 && innerwidth >= 961) {
        imageMaxWidth = 328;
        imageMaxHeight = 246;
      } else if (innerwidth <= 960 && innerwidth >= 800) {
        imageMaxWidth = 285;
        imageMaxHeight = 214;
      } else if (innerwidth <= 799 && innerwidth >= 600) {
        imageMaxWidth = 207;
        imageMaxHeight = 155;
      } else if (innerwidth <= 599 && innerwidth >= 520) {
        //Same aspect ratio as larger screens
      } else if (innerwidth <= 519 && innerwidth >= 480) {
        imageMaxWidth = 448;
        imageMaxHeight = 336;
      } else if (innerwidth <= 479 && innerwidth >= 320) {
        imageMaxWidth = 288;
        imageMaxHeight = 216;
      } else if (innerwidth <= 319 && innerwidth >= 290) {
        imageMaxWidth = 286;
        imageMaxHeight = 215;
      }
      let dimensions = that.calculateAspectRatioFit(
        image.width,
        image.height,
        imageMaxWidth,
        imageMaxHeight
      );
      let arrayImgsSizes = that.state.imgsSizes;
      arrayImgsSizes[index] = dimensions;
      that.setState({
        imgsSizes: arrayImgsSizes,
      });
    };
  };

  calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {
    let ratio = [maxWidth / srcWidth, maxHeight / srcHeight];
    ratio = Math.min(ratio[0], ratio[1]);
    return { width: srcWidth * ratio, height: srcHeight * ratio };
  };

  render() {
    const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;
    const URL = BASE_URL + "/api/prodotti/foto/";
    const imgIds = this.props.prodotto.fotoAggiuntive;
    return (
      <CarouselProvider
        visibleSlides={1}
        totalSlides={5}
        step={1}
        naturalSlideWidth={400}
        naturalSlideHeight={300}
        hasMasterSpinner
        lockOnWindowScroll
        dragEnabled={false}
      >
        {imgIds === null || imgIds.length === 0 ? (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Slider>
                <Slide key={1} index={1}>
                  <Image
                    style={{ width: "100%", height: "70%", marginTop: "40px" }}
                    alt={"placeholder_foto"}
                    src={logo}
                  />
                  {/* <ImageWithZoom src={URL + "/api/prodotti/foto/" + id} /> */}
                </Slide>
              </Slider>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={1}>
            <Box clone order={{ xs: 1, sm: 2, md: 2 }}>
              <Grid item xs={12} sm={8} md={9} lg={10} xl={11}>
                <Slider>
                  {imgIds.map((value, index) => (
                    <Slide key={value} index={index}>
                 
                      <Image
                        style={{
                          margin: "auto",
                          width: this.state.imgsSizes[index].width,
                          height: this.state.imgsSizes[index].height,
                        }}
                        alt={"foto_prodotto_" + value}
                        src={URL + value}
                      />
                      {/* <ImageWithZoom src={URL + "/api/prodotti/foto/" + id} /> */}
                    </Slide>
                  ))}
                </Slider>
              </Grid>
            </Box>
            <Box clone order={{ xs: 2, sm: 1, md: 1 }}>
              <Grid item xs={12} sm={4} md={3} lg={2} xl={1}>
                <div id="div_buttons_imgs">
                  {imgIds.map((value, index) => (
                    <Dot className="img_btn" key={value} slide={index}>
                      <img
                        style={styles.img}
                        alt={"foto_" + value}
                        src={URL + value}
                      />
                    </Dot>
                  ))}
                </div>
              </Grid>
            </Box>
          </Grid>
        )}
      </CarouselProvider>
    );
  }
}

ImmaginiProdotto.propTypes = {
  prodotto: PropTypes.object.isRequired,
};
