import React, { Component, Fragment } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BottoniStore from './BottoniStore';

import PropTypes from 'prop-types';
import LikeButton from './LikeButton';

const styles = {
    root: {
        width: '100%',
        textAlign: 'center',
        position: 'relative',
        padding: '10px'
    },
    expansionpanel: {
        margin: 'auto',
        marginTop: '10px',
        marginBottom: '10px',
        maxWidth: '460px',
    }
}

export default class DatiProdotto extends Component {

    render() {        
        let labelDimensione = "Dimensioni disponibili";
        let dimensione = "Dimensione unica"
        let colore = "Colore unico"
        if (this.props.prodotto.categoriaId === 2 || this.props.prodotto.categoriaId === 6 ||
            this.props.prodotto.categoriaId === 7 || this.props.prodotto.categoriaId === 8) {
            labelDimensione = "Taglie disponibili";
            dimensione = "Taglia unica";
        } else if (this.props.prodotto.categoriaId === 3) {
            labelDimensione = "Misure disponibili";
            dimensione = "Misura unica";
        }
        if (this.props.varianti.length > 0) {
            if (this.props.varianti[0].dimensione !== 'default') {
                dimensione = '';
                for (let i=0; i<this.props.varianti.length; i++) {
                    dimensione = dimensione + this.props.varianti[i].dimensione;
                    if (i !== this.props.varianti.length -1) {
                        dimensione = dimensione + '; ';
                    }
                }
            }
            if (this.props.varianti[0].colore !== 'default') {
                colore = '';
                for (let i=0; i<this.props.varianti.length; i++) {
                    colore = colore + this.props.varianti[i].colore;
                    if (i !== this.props.varianti.length -1) {
                        colore = colore + '; ';
                    }
                }
            }
        }
        return(
            <div style={styles.root} >
                { this.props.prodotto === null ?
                    <Typography variant="h5" gutterBottom style={{fontFamily:'Raleway'}}>
                        Nessun record
                    </Typography>
                :
                <Fragment>
                    <Typography variant="h6" style={{fontFamily:'Raleway', fontWeight:'300'}} gutterBottom>
                        { this.props.prodotto.marca === null ? "" : this.props.prodotto.marca }
                    </Typography>
                    <Typography variant="h5" style={{fontFamily:'Raleway'}} gutterBottom>
                        { this.props.prodotto.nome === null ? "" : this.props.prodotto.nome }
                    </Typography>
                    <Typography variant="subtitle1" style={{fontFamily:'Raleway'}} gutterBottom >
                        { this.props.prodotto.prezzoListino === null ? "" : this.props.prodotto.prezzoListino + " €  (iva inclusa)" }
                    </Typography>
                    
                    <div style={{width:'100%', marginTop: '10px', marginBottom:'30px'}} >
                        <ExpansionPanel style={styles.expansionpanel}>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="descrizione-content"
                            id="descrizione-header"
                            >
                                <Typography variant="body1" style={{fontFamily:'Raleway'}} >Descrizione</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography variant="body2" style={{fontFamily:'Raleway'}} >
                                   { this.props.prodotto.descrizione === null ? "" : this.props.prodotto.descrizione }
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel style={styles.expansionpanel}>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="dimensione-content"
                            id="dimensione-header"
                            >
                                <Typography variant="body1" style={{fontFamily:'Raleway'}} >{labelDimensione}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography variant="body2" style={{fontFamily:'Raleway'}} >
                                   { dimensione }
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel style={styles.expansionpanel}>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="colore-content"
                            id="colore-header"
                            >
                                <Typography variant="body1" style={{fontFamily:'Raleway'}} >Colori disponibili</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography variant="body2" style={{fontFamily:'Raleway'}} >
                                   { colore }
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel style={styles.expansionpanel}>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="composizione-content"
                            id="composizione-header"
                            >
                                <Typography variant="body1" style={{fontFamily:'Raleway'}} >Composizione</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography variant="body2" style={{fontFamily:'Raleway'}} >
                                    { this.props.prodotto.composizione === null ? "" : this.props.prodotto.composizione }
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                    <LikeButton prodottoId={this.props.prodotto.prodottoId}/>
                    {/*   <BottoniStore />*/}
                  

                </Fragment>
                }
            </div>
        );
    }

}

DatiProdotto.propTypes = {
    prodotto: PropTypes.object.isRequired,
    varianti: PropTypes.array.isRequired
}