import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';

const styles = {
    root: {
        width: '100%',
        textAlign: 'center',
        position: 'relative',
    }
}

export default class BottoniStore extends Component {

    onGooglePlayBtnClicked = () => {
        window.open(
            'https://play.google.com/store/apps/details?id=app.mercatinrete.it',
            '_blank'
        );
    }

    onAppStoreBtnClicked = () => {
        window.open(
            'https://testflight.apple.com/join/QxYTgbK0',
            '_blank'
        );
    }

    render() {
        return(
            <div style={styles.root} >
                <Typography 
                variant="h6" 
                style={{fontFamily:'Raleway', fontWeight:'300', paddingLeft:'20px', paddingRight:'20px'}} 
                gutterBottom 
                >
                    Ti piace questo prodotto?
                    Scarica l’App per iOS o Android.
                </Typography>

                <button id="ios_button" onClick={this.onAppStoreBtnClicked} />
                <button id="android_button" onClick={this.onGooglePlayBtnClicked} />
            </div>
        );
    }
}