import React, { Component } from "react";

import { IconContext } from "react-icons";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

import { IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const styles = {
  root: {
    width: "100%",
    textAlign: "center",
    position: "relative",
  },
};

export default class LikeButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      like: cookies.get(this.props.prodottoId),
      analytics: {
        likes: 0,
        views: 0,
      },
    };
  }
  componentDidMount() {
    this.fetchAnalytics();
  }
  fetchAnalytics = async () => {
    const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
    const URL =
      ENDPOINT + "/api/analytics?prodottoId.equals=" + this.props.prodottoId;
    fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        this.setState({ analytics: responseData[0] });
      });
  };
  likeApi = () => {
    const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
    const URL = ENDPOINT + "/api/analytics/like";
    let like = this.state.like;
    let body = {
      prodottoId: this.props.prodottoId,
      like: like,
    };
    console.log(body);

    if (this.state.like === true) {
      cookies.set(this.props.prodottoId, true, { path: "/" });
    } else {
      console.log("test");
      cookies.remove(this.props.prodottoId, { path: "/" });
    }

    fetch(URL, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((responseData) => {
        this.fetchAnalytics();
        console.log(responseData);
      });
  };
  onGooglePlayBtnClicked = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=app.mercatinrete.it",
      "_blank"
    );
  };

  onAppStoreBtnClicked = () => {
    window.open("https://testflight.apple.com/join/QxYTgbK0", "_blank");
  };

  render() {
    return (
      <div style={styles.root}>
        <Typography
          variant="h6"
          style={{
            fontFamily: "Raleway",
            fontWeight: "300",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
          gutterBottom
        >
          Ti piace questo prodotto ?
        </Typography>
        <div>
          {!this.state.like ? (
            <IconContext.Provider
              value={{
                color: "grey",
                size: "2em",
                className: "global-class-name",
              }}
            >
              <IconButton
                color="primary"
                aria-label="like"
                component="label"
                onClick={() => {
                  this.setState({ like: !this.state.like }, () =>
                    this.likeApi()
                  );
                }}
              >
                <AiOutlineHeart></AiOutlineHeart>
              </IconButton>
            </IconContext.Provider>
          ) : (
            <IconContext.Provider
              value={{
                color: "red",
                size: "2em",
                className: "global-class-name",
              }}
            >
              <IconButton
                color="primary"
                aria-label="like"
                component="label"
                onClick={() => {
                  this.setState({ like: !this.state.like }, () =>
                    this.likeApi()
                  );
                }}
              >
                <AiFillHeart></AiFillHeart>
              </IconButton>
            </IconContext.Provider>
          )}
          <p>likes ricevuti: {this.state.analytics.likes}</p>
        </div>
        {/* <button id="ios_button" onClick={this.onAppStoreBtnClicked} />
        <button id="android_button" onClick={this.onGooglePlayBtnClicked} /> */}
      </div>
    );
  }
}
